import validate from "/var/www/fml-webapp/node_modules/nuxt/dist/pages/runtime/validate.js";
import maintenance_45global from "/var/www/fml-webapp/middleware/maintenance.global.js";
import manifest_45route_45rule from "/var/www/fml-webapp/node_modules/nuxt/dist/app/middleware/manifest-route-rule.js";
export const globalMiddleware = [
  validate,
  maintenance_45global,
  manifest_45route_45rule
]
export const namedMiddleware = {
  authenticated: () => import("/var/www/fml-webapp/middleware/authenticated.ts"),
  auth: () => import("/var/www/fml-webapp/node_modules/@sidebase/nuxt-auth/dist/runtime/middleware/auth.mjs")
}