export default defineNuxtRouteMiddleware(async (to) => {
    const config = useRuntimeConfig()
    const isMaintenance = config.public.isMaintenance
    const allowedRoutes = ['/maintenance']

    // Handle maintenance mode
    if (isMaintenance) {
      // If trying to access any route other than allowedRoutes
      if (!allowedRoutes.includes(to.path)) {
        const isAllowedUser = await $fetch('/api/maintenance')
        
        // If user is not allowed, redirect to maintenance page
        if (!isAllowedUser) {
          return navigateTo('/maintenance')
        }
      }
    } else {
      // If not in maintenance mode and trying to access the maintenance page, redirect to home
      if (to.path === '/maintenance') {
        return navigateTo('/')
      }
    }
  })
  