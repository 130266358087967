import { defineNuxtPlugin, useAsyncData, useState } from '#app'
import { useAuth } from '#imports'
import type { SessionUser } from 'next-auth'

export default defineNuxtPlugin(async (nuxtApp) => {
  const { getSession, status: authStatus } = useAuth()

  const { data: session, execute: loadSession, status: requestStatus } = useAsyncData(
    'globalUserSession',
    () => getSession(),
    { server: false, lazy: true }
  )

  const isUserLoading: ComputedRef<boolean> = computed(() => requestStatus.value === 'pending')
  const status = useState('authStatus', () => authStatus.value)
  /** Server handler strips functions down so we cant add `hasRole` after /me fetch */
  const user: ComputedRef<SessionUser|null> = computed(
    () => session.value?.user ? {
      ...session.value?.user,
      hasRole(role: string) {
        return this.roles.includes(role)
      }
    } : null
  )

  nuxtApp.provide('loadSession', loadSession)
  nuxtApp.provide('isUserLoading', isUserLoading)
  nuxtApp.provide('authStatus', status)
  nuxtApp.provide('user', user)
  nuxtApp.provide('session', session)

  // Load session immediately if it hasn't been loaded yet
  if (import.meta.client && !session.value) {
    await loadSession()
  }
})