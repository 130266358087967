<template>
  <Suspense>
    <NuxtLayout>
      <NuxtPage />
    </NuxtLayout>

    <template #fallback>
      <div class="fixed bg-white-500/10 top-0 left-0 w-dvw h-dvh flex flex-col justify-center gap-6 text-center">
        <div class="w-full text-[#409eff]">
          <Spinner class="size-10" />
        </div>
        <div class="w-full text-gray-500">
          {{ $t('loading') }}...
        </div>
      </div>
    </template>
  </Suspense>
</template>