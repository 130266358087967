import { defineNuxtPlugin } from '#app'
import { EditPen, CirclePlus, Clock, Delete, ArrowDownBold, ArrowUpBold, Search, Right } from '@element-plus/icons-vue'

export default defineNuxtPlugin((nuxtApp) => {
  nuxtApp.vueApp.component('EditPen', EditPen)
  nuxtApp.vueApp.component('CirclePlus', CirclePlus)
  nuxtApp.vueApp.component('Clock', Clock)
  nuxtApp.vueApp.component('Delete', Delete)
  nuxtApp.vueApp.component('ArrowDownBold', ArrowDownBold)
  nuxtApp.vueApp.component('ArrowUpBold', ArrowUpBold)
  nuxtApp.vueApp.component('Search', Search)
  nuxtApp.vueApp.component('Right', Right)
})
