import type { SessionUser, Session } from 'next-auth'

export default () => {
  const { signIn, signOut: authSignOut } = useAuth()
  const nuxtApp = useNuxtApp()
  const router = useRouter()
  const route = useRoute()

  const loadSession = nuxtApp.$loadSession as () => Promise<Session>
  const isUserLoading = nuxtApp.$isUserLoading as ComputedRef<boolean>
  const status = nuxtApp.$authStatus as ComputedRef<string>
  const user = nuxtApp.$user as ComputedRef<SessionUser>
  const session = nuxtApp.$session as ComputedRef<Session>
  const i18n = nuxtApp.$i18n

  const signOut = async (options: any = { redirect: false }) => {
    // clear cookie
    await authSignOut(options)
    // update cached session.user since cookie is gone
    loadSession()

    // redirect to homepage if protected route
    if (route.meta.middleware === 'authenticated') {
      router.push(i18n.t('/'))
    }
  }

  return {
    signIn,
    signOut,
    status,
    loadSession,
    isUserLoading,
    user,
    session
  }
}