import { library } from '@fortawesome/fontawesome-svg-core'
import { faUserSecret, faMicrophone, faMicrophoneSlash, faVideo, faVideoSlash, faPhoneSlash } from '@fortawesome/free-solid-svg-icons'
import { faFacebookSquare, faGoogle, faLinkedin } from '@fortawesome/free-brands-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

library.add(faUserSecret)
library.add(faMicrophone)
library.add(faMicrophoneSlash)
library.add(faVideo)
library.add(faVideoSlash)
library.add(faPhoneSlash)
library.add(faFacebookSquare)
library.add(faGoogle)
library.add(faLinkedin)

export default defineNuxtPlugin((nuxtApp) => {
  nuxtApp.vueApp.component('font-awesome-icon', FontAwesomeIcon)
})
