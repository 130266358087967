import { defineNuxtPlugin } from '#app/nuxt'
import { LazyIconLawyerAndFirmBriefcase, LazyIconLawyerAndFirmBuilding, LazyIconLawyerAndFirmGlobe, LazyIconLawyerAndFirmLocation, LazyIconArrowFullLeft, LazyIconArrowFullRight, LazyIconArrowLeft, LazyIconArrowRight, LazyIconBlankUser, LazyIconBusiness, LazyIconCloseButton, LazyIconGtLogo, LazyIconInfo, LazyIconMinus, LazyIconOldschoolArrow, LazyIconPlus, LazyIconReplay, LazyIconSearch, LazyIconSocialsFacebook, LazyIconSocialsLinkedin, LazyIconSocialsMessenger, LazyIconSwap } from '#components'
const lazyGlobalComponents = [
  ["IconLawyerAndFirmBriefcase", LazyIconLawyerAndFirmBriefcase],
["IconLawyerAndFirmBuilding", LazyIconLawyerAndFirmBuilding],
["IconLawyerAndFirmGlobe", LazyIconLawyerAndFirmGlobe],
["IconLawyerAndFirmLocation", LazyIconLawyerAndFirmLocation],
["IconArrowFullLeft", LazyIconArrowFullLeft],
["IconArrowFullRight", LazyIconArrowFullRight],
["IconArrowLeft", LazyIconArrowLeft],
["IconArrowRight", LazyIconArrowRight],
["IconBlankUser", LazyIconBlankUser],
["IconBusiness", LazyIconBusiness],
["IconCloseButton", LazyIconCloseButton],
["IconGtLogo", LazyIconGtLogo],
["IconInfo", LazyIconInfo],
["IconMinus", LazyIconMinus],
["IconOldschoolArrow", LazyIconOldschoolArrow],
["IconPlus", LazyIconPlus],
["IconReplay", LazyIconReplay],
["IconSearch", LazyIconSearch],
["IconSocialsFacebook", LazyIconSocialsFacebook],
["IconSocialsLinkedin", LazyIconSocialsLinkedin],
["IconSocialsMessenger", LazyIconSocialsMessenger],
["IconSwap", LazyIconSwap],
  
]

export default defineNuxtPlugin({
  name: 'nuxt:global-components',
  setup (nuxtApp) {
    for (const [name, component] of lazyGlobalComponents) {
      nuxtApp.vueApp.component(name, component)
      nuxtApp.vueApp.component('Lazy' + name, component)
    }
  }
})
