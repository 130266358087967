<template>
  <svg class="spinner-base inline" viewBox="0 0 50 50">
    <circle class="spinner-path" cx="25" cy="25" r="20" fill="none" stroke="currentColor" />
  </svg>
</template>

<style scoped>
.spinner-path {
  animation: loading-dash 1.5s ease-in-out infinite;
  stroke-dasharray: 90, 150;
  stroke-dashoffset: 0;
  stroke-width: 2;
  stroke-linecap: round;
}

.spinner-base {
  animation: loading-rotate 2s linear infinite;
}

@keyframes loading-dash {
  0%{
    stroke-dasharray: 1, 200;
    stroke-dashoffset: 0;
  }
  50%{
    stroke-dasharray: 90, 150;
    stroke-dashoffset: -40px;
  }
  to {
    stroke-dasharray: 90, 150;
    stroke-dashoffset: -120px;
  }
}

@keyframes loading-rotate{
  to {
    transform: rotate(1turn)
  }
}
</style>